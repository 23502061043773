<template>
      
        <div class="col-md-12">
            <div class="card ">
                
                <header 
                    class="card-header d-flex align-items-center justify-content-between" 
                    style="background:white">
                    <h2 
                        class="h4 card-header-title font-size-18"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        <i class="font-size-22 bx bx-line-chart"></i>
                        {{$t('chart.users_during_the_year')}}
                    </h2>
                                    
                        <!-- Dropdown -->
                        <span class="ti-more pull-right cursor-pointer"
                                id="dropMenuCharts" 
                                data-toggle="dropdown" 
                                aria-haspopup="true" 
                                aria-expanded="false">
                        </span>
                        <div class="dropdown-menu" aria-labelledby="dropMenuCharts">
                            <span class="dropdown-item " 
                                    :class="(type == 'weekly') ? 'active' : ''"
                                    @click="fetchLineChart('weekly')">Weekly
                            </span>
                            <span class="dropdown-item "
                                    :class="(type == 'monthly') ? 'active' : ''"
                                    @click="fetchLineChart('monthly')">Monthly
                            </span>
                            <span class="dropdown-item "
                                    :class="(type == 'quarter') ? 'active' : ''"
                                    @click="fetchLineChart('quarter')">Quarter
                            </span>
                            <span class="dropdown-item "
                                    :class="(type == 'yearly') ? 'active' : ''"
                                    @click="fetchLineChart('yearly')">Yearly
                            </span>
                        </div>
                        <!-- End Dropdown -->
                </header>

                <div class="card-body pt-0 text-center">
                    <span v-if="loading">
                        <div 
                            class="spinner-grow spinner-grow-md mr-1 ui-mt150" 
                            role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    <apexchart 
                        v-if="!loading" 
                        class="mt-md-3 mt-xl-0"
                        type=area 
                        :options="options" 
                        :series="series"  
                        height="380"
                        style="direction:ltr" />
                </div>
            
            </div>
        </div>

</template>

<script>
export default {
    name: 'LineChart',
    props: [
        'table',
    ],
    data(){
        return {
            //
            auth: {
                role: '',
                access_token: '',
            },
            
            series: [],
            type: 'monthly',
            loading: false,
            options: {
                chart: { height: 450, zoom: { enabled: false }},
                animations: { enabled: true },
                dataLabels: {  enabled: false },
                stroke: {  curve: 'straight' },
                title: { text: '', align: 'center' },
                grid: {  row: { colors: ['#f3f3f3', 'transparent'],  opacity: 0.5 }},
                markers: { size: 5, align:top,  hover: {  sizeOffset: 5  }},
            },
        }
    },
    mounted() {},
    watch: {
        //
    },
    created() {
        // AccessToken
        if(localStorage.getItem('access_token')) {
            this.auth.access_token = localStorage.getItem('access_token');
        }
        if(localStorage.getItem('role')) {
            this.auth.role = localStorage.getItem('role');
        }
        this.fetchLineChart(this.type);

        this.series = [{ name:'Views', data: [] }];
        this.options.xaxis = { categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"] };
    },
    methods: {

        // fetch LineChart
        fetchLineChart(type){
            this.loading = true;
            this.type = type;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/reports/lineChart/users/'+type,
                method: 'GET',
                data: {},
                params: {}
            }
            this.axios(options)
            .then(res => {
                this.loading = false;
                this.series = [{ name:'Users', data: res.data.rows.series }];
                this.options.xaxis = { categories: res.data.rows.xaxis };
            })
            .catch(() => {})
            .finally(() => {});
        },


        removeLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_image');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('role');
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
